<template>
  <div class="px-6 pt-4 pb-9">
    <div class="flex justify-end mb-4">
      <display-limit-selector
        v-model="submissionsData.limit"
        class="mr-4"
        :options="limitOptions"
        @click="handlePageUpdate(1)"
      />
      <base-pagination
        :model-value="submissionsData.page"
        :total-pages="submissionsData.pageCount"
        :limit="submissionsData.limit"
        :total-count="submissionsData.totalCount"
        @update:modelValue="handlePageUpdate($event, true)"
      />
    </div>
    <div class="flex items-center mr-4 mb-4">
      <icon-button
        title="Refresh"
        class="mr-3 w-7.5 h-7.5"
        icon="icons/arrowsClockwise.svg"
        active
        active-btn-class="bg-custom-green-1 bg-opacity-20"
        active-icon-class="text-custom-green-1"
        @click="handleRefresh()"
      />
      <base-select
        :model-value="submissionFilters.searchScope"
        class="text-sm max-w-full w-60 text-left mr-3"
        multi-select
        placeholder="-Select Columns-"
        :options="submissionScopeOptions"
        show-default-option
        @update:model-value="filterSearchKeyword($event)"
      />
      <inline-button-input
        v-model="submissionFilters.searchKeyword"
        container-class="max-w-full w-80 mr-4"
        placeholder="Search..."
        :btn-props="{
          text: 'Search',
          variant: 'btn-primary'
        }"
        :input-props="{
          containerClass: 'flex items-center w-full'
        }"
        @click="searchByKeyword()"
      />
      <template v-if="submissionFilters.searchScope.length > 0">
        <icon-button
          class="mr-3 w-7 h-7"
          icon="icons/trash.svg"
          active
          active-btn-class="bg-custom-orange-1"
          active-icon-class="text-white"
          @click="clearSearchScope()"
        />
        <div
          v-for="(searchScope, sIndex) in submissionFilters.searchScope"
          :key="'simple-search-field' + sIndex"
          class="flex items-center py-0.5 px-2 bg-custom-green-1 bg-opacity-25 mr-3 rounded-sm border border-custom-green-1 border-opacity-20 h-7"
        >
          <span class="text-custom-green-3 text-sm mr-1 truncate">
            {{ searchScope }}
          </span>
          <base-svg
            class="h-3.5 w-3.5 text-custom-green-3 inline-block hover:text-primary-red cursor-pointer align-middle"
            src="icons/cross.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
            @click="deleteSearchScope(sIndex)"
          />
        </div>
      </template>
      <base-select
        :model-value="submissionFilters.columnChooser"
        multi-select
        class="text-sm max-w-full w-64 text-left"
        placeholder="Choose columns to display"
        text-value-override="Choose columns to display"
        :options="submissionColumnChooserOptionsArray"
        @update:model-value="filterColumnChooser($event, submissionFilters.columnChooser)"
      />
    </div>
    <div
      v-if="submissionTableLoading"
      class="mt-40 text-center"
    >
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <template v-else-if="submissionsData.tableData.data.length > 0">
      <brand-assurance-table
        id="DraftsTable"
        v-model="submissionsData.tableData"
        root-element-class="mb-20"
        :show-check-box-column="false"
        are-columns-resizable
        are-columns-interchangable
        use-custom-sort-logic
        :are-columns-sortable="!isSubmissionTableSorting"
        @columnDragged="savePreferences()"
        @column-resized="colResized()"
        @columnSorted="sortSubmissionTable($event)"
      >
        <!-- @columnResized="savePreferences()" -->
        <!-- inject link in submission ID column -->
        <template
          v-for="(submissionIdCellSlotName, submissionIndex) in submissionIdCellSlotNames"
          :key="submissionIdCellSlotName"
          #[submissionIdCellSlotName]
        >
          <span
            class="underline cursor-pointer"
            @click="openSubmissions({
              submissionId: submissionsData.tableData.data[submissionIndex].submissionId,
              licenseeName: submissionsData.tableData.data[submissionIndex].licenseeName,
              assignUser: submissionsData.tableData.data[submissionIndex].assignUser,
              submissionStatus: submissionsData.tableData.data[submissionIndex].submissionStatus,
              currentStepName: submissionsData.tableData.data[submissionIndex].currentStep,
            })"
          >
            {{ submissionsData.tableData.data[submissionIndex].submissionId }}
          </span>
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(submissionDateCellSlotName, submissionIndex) in submissionDateCellSlotNames"
          :key="submissionDateCellSlotName"
          #[submissionDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].creationDate ? moment(submissionsData.tableData.data[submissionIndex].creationDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(lastUpdatedCellSlotName, submissionIndex) in lastUpdatedCellSlotNames"
          :key="lastUpdatedCellSlotName"
          #[lastUpdatedCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].lastmodifiedDate ? moment(submissionsData.tableData.data[submissionIndex].lastmodifiedDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(currentStepDateCellSlotName, submissionIndex) in currentStepDateCellSlotNames"
          :key="currentStepDateCellSlotName"
          #[currentStepDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].stepStartDate ? moment(submissionsData.tableData.data[submissionIndex].stepStartDate).format('MM/DD/YYYY') : '' }}
        </template>
        <!-- inject formatted date in date column -->
        <template
          v-for="(assignedDateCellSlotName, submissionIndex) in assignedDateCellSlotNames"
          :key="assignedDateCellSlotName"
          #[assignedDateCellSlotName]
        >
          {{ submissionsData.tableData.data[submissionIndex].deliveryDate ? moment(submissionsData.tableData.data[submissionIndex].deliveryDate).format('MM/DD/YYYY') : '' }}
        </template>
      </brand-assurance-table>
      <div class="flex justify-end">
        <display-limit-selector
          v-model="submissionsData.limit"
          class="mr-4"
          :options="limitOptions"
          @click="handlePageUpdate(1)"
        />
        <base-pagination
          :model-value="submissionsData.page"
          :total-pages="submissionsData.pageCount"
          :limit="submissionsData.limit"
          :total-count="submissionsData.totalCount"
          @update:modelValue="handlePageUpdate($event, true)"
        />
      </div>
    </template>
    <!-- submision form modal -->
    <brand-assurance-submission-form-modal
      v-model="showSubmissionFormModal"
      submission-form-mode="DRAFT"
      @hide="setSubmissionFormModalVisibility(false)"
      @show="setSubmissionFormModalVisibility(true)"
      @minimize="handleSubmissionFormModalMinimize()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, reactive, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useBaTable from '@/hooks/baTable.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useSubmissionFormModal from '@/components/brand-assurance-submission-form/submissionFormModal.js';
import { WARNING, MAX_FIVE_OPTIONS, SEARCH_KEYWORD_REQUIRED, SELECT_ATLEAST_ONE_COLUMN, KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED, NO_SUBMISSIONS_FOUND } from '@/constants/alerts.js';
import moment from 'moment';

export default {
    name: 'BADrafts',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        InlineButtonInput: defineAsyncComponent(() => import('@/components/InlineButtonInput.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BrandAssuranceSubmissionFormModal: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/BrandAssuranceSubmissionFormModal.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { generateTableSlotNamesByColumnKey } = useBaTable();
        const { fetchedUserPreference } = useSubmissionForm();
        const { updateSubmissionsTabList, resetSubmissionFormModal } = useSubmissionFormModal();
        resetSubmissionFormModal();
        const { notificationsStack } = useToastNotifications();

        // submission table action button
        const handleRefresh = () => {
            fetchSubmissions();
        };

        // filter logic
        const submissionFilters = reactive({
            searchScope: [],
            searchKeyword: '',
            columnChooser: []
        });

        const filterSearchKeyword = (selectedOption) => {
            if (selectedOption.includes('Global Search') && !submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = ['Global Search'];
            } else if (selectedOption.includes('Global Search') && submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = selectedOption.filter(e => e !== 'Global Search');
            } else if (!selectedOption.includes('Global Search') && !submissionFilters.searchScope.includes('Global Search')) {
                submissionFilters.searchScope = selectedOption;
            }
            if (submissionFilters.searchScope.length > 5) {
                submissionFilters.searchScope = submissionFilters.searchScope.slice(0, 5);
                notificationsStack.value.push({
                    type: WARNING,
                    message: MAX_FIVE_OPTIONS
                });
            }
        };
        const currentUser = computed(() => store.getters['auth/getUserId']);

        const savePreferences = async () => {
            try {
                let params;
                if (fetchedUserPreference?.value) {
                    fetchedUserPreference.value.style.draft_preference = submissionsData.tableData;
                    fetchedUserPreference.value.style.draft_preference.displayCountPreference = submissionsData.limit;
                    params = fetchedUserPreference.value;
                } else {
                    params = {
                        key: currentUser.value,
                        label: 'Preferences',
                        style: {
                            draft_preference: {
                                ...submissionsData.tableData,
                                displayCountPreference: submissionsData.limit
                            }
                        }
                    };
                }

                if (params) {
                    await store.dispatch('users/saveUserPreferences', { params });
                }
            } catch (err) {
                console.error(err);
            }
        };

        const searchScopeKeys = computed(() => {
            if (submissionFilters.searchScope.length > 0) {
                return submissionFilters.searchScope.map(column => {
                    const matchedCol = submissionsData.tableData.columns.find(tableCol => tableCol.label === column);
                    if (matchedCol) {
                        return matchedCol.key;
                    } else {
                        return '';
                    }
                }).filter(el => el);
            }
            return [];
        });
        const submissionScopeOptions = computed(() => ['Global Search', ...submissionsData.tableData.columns.map(col => col.label)]);
        const searchByKeyword = () => {
            if (submissionFilters.searchScope.length > 0 && !submissionFilters.searchKeyword) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SEARCH_KEYWORD_REQUIRED
                });
                return;
            } else if (submissionFilters.searchKeyword && !submissionFilters.searchScope.length) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SELECT_ATLEAST_ONE_COLUMN
                });
                return;
            } else if (!submissionFilters.searchKeyword && !submissionFilters.searchScope.length) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED
                });
                return;
            }
            submissionsData.page = 1;
            fetchSubmissions();
        };
        const deleteSearchScope = (index) => {
            submissionFilters.searchScope.splice(index, 1);
        };
        const clearSearchScope = () => {
            submissionFilters.searchScope = [];
            handleRefresh();
        };

        const filterColumnChooser = (selectedOption, val) => {
            submissionsData.tableData.columns = actualListOfCols.value.filter(c => selectedOption.includes(c.label));

            let params;
            if (fetchedUserPreference?.value) {
                fetchedUserPreference.value.style.draft_preference.columns = submissionsData.tableData.columns;
                params = fetchedUserPreference.value;
            } else {
                params = {
                    key: currentUser.value,
                    label: 'Preferences',
                    style: {
                        draft_preference: {
                            columns: submissionsData.tableData.columns
                        }
                    }
                };
            }
            store.dispatch('users/saveUserPreferences', { params });
            store.dispatch('userPrefs/updateSubmissionColumns', { submissionColumns: [...submissionsData.tableData.columns] });
        };

        const actualListOfCols = computed(() => store.getters['userPrefs/getActualListOfCols']);
        const submissionColumnChooserOptionsArray = computed(() =>
            actualListOfCols.value.filter(c => !['Submission Id'].includes(c.label)).map(c => c.label)
        );

        onMounted(() => {
            submissionFilters.columnChooser = cachedSubmissionColumns.value.length
                ? cachedSubmissionColumns.value.map(c => c.label) : actualListOfCols.value.map(c => c.label);
        });

        // submission table logic
        const cachedSubmissionColumns = computed(() => store.getters['userPrefs/getSubmissionColumns']);
        const fetchedSubmissions = computed(() => store.getters['baSubmissions/getSubmissionsList']);
        const submissionsData = reactive({
            tableData: {
                columns: cachedSubmissionColumns.value.length ? cachedSubmissionColumns.value : actualListOfCols.value,
                data: []
            },
            page: 1,
            limit: fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference : 50,
            totalCount: fetchedSubmissions.value ? fetchedSubmissions.value.totalCount : 0,
            pageCount: fetchedSubmissions.value ? fetchedSubmissions.value.pageCount : 0
        });

        const submissionIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'submissionId'));
        const submissionDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'creationDate'));
        const lastUpdatedCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'lastUpdated'));
        const currentStepDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'dateAtCurrentStep'));
        const assignedDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'deliveryDate'));
        const selectedSubmissions = computed(() => submissionsData.tableData && submissionsData.tableData.data.length && submissionsData.tableData.data.filter(tableObj => tableObj.selected));
        const updateSubmissionsData = () => {
            submissionsData.tableData.data = fetchedSubmissions.value.data;
            submissionsData.pageCount = fetchedSubmissions.value.pageCount;
            submissionsData.totalCount = fetchedSubmissions.value.totalCount;
            if (selectedSubmissions.value.length > 0) {
                submissionsData.tableData.data.forEach(tableObj => {
                    tableObj.selected = false;
                });
            }
            if (submissionsData.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_SUBMISSIONS_FOUND
                });
            }
        };
        const submissionTableLoading = ref(true);
        const fetchSubmissions = async (shouldEntireTableReload = true) => {
            try {
                if (shouldEntireTableReload) {
                    submissionTableLoading.value = true;
                }

                const bodyPayload = {
                    page: submissionsData.page,
                    limit: fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference : 50,
                    ...sortingFilters,
                    submissionStatus: 'Draft',
                    searchScope: submissionFilters.searchKeyword.length > 0 ? searchScopeKeys.value : [],
                    searchKeyword: submissionFilters.searchKeyword
                };

                if (fetchedUserPreference?.value && fetchedUserPreference.value?.style && fetchedUserPreference.value?.style?.draft_preference && fetchedUserPreference.value?.style?.draft_preference?.columns) {
                    submissionsData.tableData.columns = fetchedUserPreference.value?.style?.draft_preference?.columns;
                }

                await store.dispatch('baSubmissions/searchSubmissions', { bodyPayload });
                if (fetchedSubmissions.value && fetchedSubmissions.value.data) {
                    updateSubmissionsData();
                    const comingFromNewSubmissionForm = JSON.parse(localStorage.getItem('comingFromNewSubmissionForm', true));
                    // only open from draft when saved new submission form.
                    if (comingFromNewSubmissionForm) {
                        setTimeout(async () => {
                            openSubmissions({
                                submissionId: submissionsData.tableData.data[0].submissionId,
                                licenseeName: submissionsData.tableData.data[0].licenseeName,
                                assignUser: submissionsData.tableData.data[0].assignUser,
                                submissionStatus: submissionsData.tableData.data[0].submissionStatus,
                                currentStepName: submissionsData.tableData.data[0].currentStep
                            });
                        }, 1000);
                        // comingFromNewSubmissionForm = ''
                        localStorage.setItem('comingFromNewSubmissionForm', false); // remove from localStorage as soon as it is opened.
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                submissionTableLoading.value = false;
            }
        };
        onMounted(() => {
            store.dispatch('baSubmissions/clearSubmissions');
            setUserPreferences();
        });

        const setUserPreferences = async () => {
            try {
                submissionsData.limit = fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.draft_preference?.displayCountPreference : 50;
                await fetchSubmissions();
            } catch (err) {
                console.error(err);
            }
        };

        const limitOptions = ref([
            50,
            100,
            200
        ]);
        const handlePageUpdate = async (newPage, paginator = false) => {
            submissionsData.page = newPage;
            if (!paginator) await savePreferences();
            await fetchSubmissions();
        };

        // submissions table tasks logic
        const openSubmissions = (newSubmission) => {
            const submissionsToBeOpened = [newSubmission];
            updateSubmissionsTabList(submissionsToBeOpened);
            setSubmissionFormModalVisibility(true);
        };
        const showSubmissionFormModal = ref(false);
        const setSubmissionFormModalVisibility = (visibility) => {
            showSubmissionFormModal.value = visibility;
        };
        const handleSubmissionFormModalMinimize = () => {
            showSubmissionFormModal.value = false;
        };

        // submissions table sort logic
        const isSubmissionTableSorting = ref(false);
        const sortingFilters = reactive({
            sort: ''
        });
        const sortSubmissionTable = async (columnPayload) => {
            try {
                isSubmissionTableSorting.value = true;
                if (columnPayload.sortOrder) {
                    sortingFilters.sort = `${columnPayload.columnKey},${columnPayload.sortOrder}`;
                } else {
                    sortingFilters.sort = '';
                }
                await fetchSubmissions(false);
            } catch (err) {
                console.error(err);
            } finally {
                // update sorting order in UI
                submissionsData.tableData.columns[columnPayload.columnIndex].sorted = columnPayload.sortOrder;
                isSubmissionTableSorting.value = false;
            }
        };

        watch(
            () => showSubmissionFormModal.value,
            () => {
                if (!showSubmissionFormModal.value) {
                    handleRefresh();
                }
            }
        );

        const colResized = () => {
            savePreferences();
        };

        return {
            // filters
            submissionFilters,
            submissionScopeOptions,
            searchByKeyword,
            deleteSearchScope,
            clearSearchScope,
            // submission action button
            handleRefresh,
            // submissions table
            submissionsData,
            filterSearchKeyword,
            submissionIdCellSlotNames,
            submissionDateCellSlotNames,
            lastUpdatedCellSlotNames,
            currentStepDateCellSlotNames,
            assignedDateCellSlotNames,
            submissionTableLoading,
            limitOptions,
            handlePageUpdate,
            openSubmissions,
            showSubmissionFormModal,
            setSubmissionFormModalVisibility,
            handleSubmissionFormModalMinimize,
            // submissions table sort
            isSubmissionTableSorting,
            sortSubmissionTable,
            savePreferences,
            filterColumnChooser,
            submissionColumnChooserOptionsArray,
            moment,
            colResized
        };
    }
};
</script>
