export default () => {
    // row color logic
    const addRowColorAndAssignedTo = tableData => tableData.map(submission => {
        return {
            ...submission,
            rowColor: getTableRowColor(submission),
            assignedTo:
              `${submission.assignUser ? `${submission.assignUser}` : ''}`+
              `${submission.assignUser ? ` (${submission.assigneeDisplayName})` : ''}`,
        };
    });
    const getTableRowColor = (submission) => {
        let rowColor = '';
        if (submission.submissionStatus === 'Completed') {
            rowColor = 'bg-custom-green-2';
        } else if (['Rejected', 'Cancelled'].includes(submission.submissionStatus)) {
            rowColor = 'bg-custom-red-1';
        } else if (submission.submissionStatus === 'Inflight' && submission.assignUser) {
            rowColor = 'bg-custom-yellow-1 bg-opacity-50';
        }
        return rowColor;
    };

    // table cell slot logic
    const generateTableSlotName = (rowIndex, colIndex) => {
        return `cell${rowIndex},${colIndex}`;
    };
    // @NOTE: Columns that don't exist in table object but are rendered in DOM are extra/additional columns (eg: checkbox column in some sections)
    const generateTableSlotNamesByColumnIndex = (table, columnIndex, numberOfExtraColumns = 0) => {
        return table.data.map((_, rowIndex) => generateTableSlotName(rowIndex, columnIndex + numberOfExtraColumns));
    };
    const generateTableSlotNamesByColumnKey = (table, columnKey, numberOfExtraColumns = 0) => {
        const columnIndex = table.columns.findIndex(col => col.key === columnKey);
        return generateTableSlotNamesByColumnIndex(table, columnIndex, numberOfExtraColumns);
    };

    return {
        addRowColorAndAssignedTo,
        generateTableSlotName,
        generateTableSlotNamesByColumnIndex,
        generateTableSlotNamesByColumnKey
    };
};
